import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  media: {
    height: 80,
  },
}));

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function InvitationPage() {
  let query = useQuery();
  const classes = useStyles();

  console.log(query.get("from"));

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              {query.get("from")} hat dich zu seiner/ihrer Sharing Gruppe
              eingeladen:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary" align="center">
              {query.get("about")}
            </Typography>
          </Grid>

          <Grid item>
            <img
              className={classes.media}
              src="/images/smiley-cool.png"
              alt="confirm"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Melde dich jetzt bei Zazmo an, um Teil der Gruppe zu werden.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              component={Link}
              to={"/"}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              Zum Login
            </Button>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}

import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import * as http from "../utils/http";

// const _baseURL = process.env.REACT_APP_SERVER_BASE_URL;

interface RequestConfig {
  url: string;
  method?: "post" | "get";
  headers?: any;
  data?: any | null;
  config?: any | null;
}

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const sendRequest = useCallback(
    async (requestConfig: RequestConfig, applyData: any) => {
      setIsLoading(true);
      setError(null);
      const token = await getAccessTokenSilently();
      // try {
      //   console.log(requestConfig);
      //   const response = await fetch(_baseURL + requestConfig.url, {
      //     method: requestConfig.method ? requestConfig.method : "GET",
      //     headers: requestConfig.headers
      //       ? requestConfig.headers
      //       : {
      //           "Content-Type": "application/json",
      //           Authorization: `Bearer ${token}`,
      //         },
      //     body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      //   });

      //   if (!response.ok) {
      //     throw new Error("Request failed!");
      //   }

      //   const data = await response.json();
      //   console.log(data);
      //   applyData(data);
      // } catch (err) {
      //   setError(err.message || "Something went wrong");
      // }

      /// Start Using AXIOS
      const url = requestConfig.url;
      const method = requestConfig.method ?? "get";
      const data = requestConfig.data ?? null;

      http
        .authorized(token)
        [method](url, data)
        .then((response) => {
          console.log(response.data);
          applyData(response.data);
          setResponse(response.data);

          setIsLoading(false);
        })
        .catch((error) => {
          // const err = axios.isCancel(ex)
          //   ? "Request cancelled"
          //   : ex.code === "ECONNABORTED"
          //   ? "A timeout has occurred"
          //   : ex.response.status === 404
          //   ? "Resource not found"
          //   : "An unexpected error has occurred";
          // console.log(error);
          setError(error.message || "Something went wrong");

          setIsLoading(false);
        });

      /// End Using AXIOS
    },
    [getAccessTokenSilently]
  );

  return {
    response,
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;

import { makeStyles } from "@material-ui/core/styles";
import Info from "@material-ui/icons/InfoOutlined";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Grid, IconButton, Paper } from "@material-ui/core";
import { theme } from "../../../Theme";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "16px",
  },
  iconButton: {
    padding: 0,
  },
});

interface OrderHistory {
  totalPrice: number;
  buyerProtectionFee: number;
}

export default function OrderHistoryCard(props: OrderHistory) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Bestellübersicht</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">Preis</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" align="right">
              {(props.totalPrice / 100).toFixed(2).replace(".", ",")} €
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} direction="row">
              <Grid item>
                <Typography variant="body2">Zazmo-Käuferschutz</Typography>
              </Grid>
              <Grid item>
                <IconButton aria-label="info" className={classes.iconButton}>
                  <Info fontSize="small" color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" align="right">
              {(props.buyerProtectionFee / 100).toFixed(2).replace(".", ",")} €
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={8}>
            <Grid item>
              <Typography variant="body2">Gesamtkosten</Typography>
              <Typography variant="caption">Abbuchungszyklus</Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item>
              <Typography variant="body2" align="right">
                {((props.totalPrice + props.buyerProtectionFee) / 100)
                  .toFixed(2)
                  .replace(".", ",")}{" "}
                €
              </Typography>
              <Typography display="block" variant="caption" align="right">
                Monatlich
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

import { useContext } from "react";
import UserContext, { UserContextInterface } from "./userContext";

/**
 * ```js
 * const {
 *   // User state:
 *   isOnboarded,
 * } = useUser();
 * ```
 *
 * Use the `useUser` hook in your components to access the auth state and methods.
 */
const useUser = (): UserContextInterface => useContext(UserContext);

export default useUser;

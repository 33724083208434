import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Group } from "../../../interfaces/group.interface";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 100,
  },
  card: {
    paddingTop: theme.spacing(2),
  },
}));

interface JoinGroupSuccessProps {
  group: Group;
}

export default function JoinGroupSuccess(props: JoinGroupSuccessProps) {
  const classes = useStyles();
  const group = props.group;
  return (
    <React.Fragment>
      <div className={classes.card}>
        <Grid
          container
          spacing={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4" align="center">
              Willkommen in der Sharing Gruppe
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center" color="primary">
              {group.name}
            </Typography>
          </Grid>
          <Grid item>
            <img
              className={classes.media}
              src="/images/hand-shake.png"
              alt="confirm"
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              Wir werden jeden
              <span style={{ color: "#F57D7E" }}>
                {` ${((group.service.price.amount + 119) / 100)
                  .toString()
                  .replace(".", ",")} €/Monat. `}
              </span>
              Bitte achte darauf das dein Konto gedeckt ist.
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Button
              component={Link}
              to={`/group-details/${group.id}`}
              variant="contained"
              color="primary"
              fullWidth
            >
              Zur Gruppe
            </Button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

import { makeStyles, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import JoinGroupSignup from "./components/JoinGroupSignup";
import JoinGroupSuccess from "./components/JoinGroupSuccess";
import useHttp from "../../hooks/use-http";
import { Group } from "../../interfaces/group.interface";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  content: {
    padding: theme.spacing(2),
  },
  media: {
    height: 100,
  },
  card: {
    paddingTop: theme.spacing(2),
  },
}));

enum JoinGroupState {
  signup,
  success,
}


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function JoinGroupView() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  let query = useQuery();

  const [group, setGroup] = useState<Group>();
  const [joinGroupState, setJoinGroupState] = useState<JoinGroupState>(
    JoinGroupState.signup
  );

  const { isLoading, error, sendRequest: fetchGroup } = useHttp();

  useEffect(() => {
    const transformGroup = (group: Group) => {
      console.log(group);
      setGroup(group);
    };
    fetchGroup({ url: `/rest/v1/sharing-groups/${id}` }, transformGroup);
  }, [fetchGroup, id]);

  const joinGroupSuccess = () => {
    setJoinGroupState(JoinGroupState.success);
  };


  useEffect(() => {
    console.log(query.get('isSuccess'));
    if (query.get('isSuccess')) {
      setJoinGroupState(JoinGroupState.success);
    }
    if(error) {
      console.log(error);
    }
  }, [query, error]);

  return (
    <React.Fragment>
      {isLoading && <LinearProgress />}
      {error && <div>Error, the backend moved to the dark side.</div>}
      {group && (
        <main className={classes.layout}>
          <React.Fragment>
            {joinGroupState === JoinGroupState.signup ? (
              <div className={classes.content}>
                <JoinGroupSignup
                  group={group}
                  joinGroupSuccess={joinGroupSuccess}
                />
              </div>
            ) : (
              <div className={classes.content}>
                <JoinGroupSuccess group={group} />
              </div>
            )}
          </React.Fragment>
        </main>
      )}
    </React.Fragment>
  );
}


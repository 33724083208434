import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { theme } from "../../../Theme";

const useStyles = makeStyles({
  root: {
    height: 80,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2F2F2F",

    [theme.breakpoints.up("sm")]: {
      backgroundColor: "transparent",
    },
  },

  button: {
    minWidth: "145px",
    "&:only-child": {
      marginLeft: "auto",
    },
  },
});

interface GroupDetailsFooterProps {
  id: string;
  totalPrice: number;
  availableSlots: number;
}

export default function GroupDetailsFooter(group: GroupDetailsFooterProps) {
  const classes = useStyles();

  const handleJoin = () => {
    console.log(`join group ${group.id}`);
  };

  return (
    <div className={classes.root}>
      <div>
        <div>
          {(group.totalPrice / 100).toFixed(2).replace(".", ",")} €/Monat
        </div>
        <div>
          {group.availableSlots} Slot{group.availableSlots > 1 ? "s" : ""}
        </div>
      </div>
      <Button
        component={Link}
        to={`/join-group/${group.id}`}
        variant="contained"
        onClick={handleJoin}
        color="primary"
        className={classes.button}
      >
        Beitreten
      </Button>
    </div>
  );
}

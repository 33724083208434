import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {
  Container,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

export default function StyleguideView() {
  const [buttonState, toggleButtonState] = useState(false);
  const handleButtonClick = () => toggleButtonState(!buttonState);
  return (
    <Container maxWidth="xs">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Button
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleButtonClick}
              >
                Primary Button
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                disabled
                onClick={handleButtonClick}
              >
                Primary Button
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleButtonClick}
              >
                Secondary
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={handleButtonClick}
              >
                Primary Button
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleButtonClick}
              >
                S-Button
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Textfields
              </Typography>
              <TextField
                id="text-field"
                label="Label"
                variant="filled"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error
                id="text-field-error"
                label="Label"
                variant="filled"
                size="small"
                defaultValue="Hello World"
                helperText="Incorrect entry."
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="selected-service"
                variant="filled"
                label="Kategorie"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle show categories"
                        onClick={() => console.log("test")}
                      >
                        <ExpandMore />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

import Grid from "@material-ui/core/Grid";
import { Container, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import CreateGroupCard from "./components/CreateGroupCard";
import ListGroupsCard from "./components/ListGroupsCard";
import CategoriesList from "./components/CategoriesList";
import ServicesList from "./components/ServicesList";
import GroupsGrid from "./components/GroupsGrid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "64px",
    },
    cardGrid: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    toggleCategoryButton: {
      margin: theme.spacing(0.5),
      borderRadius: 20,
      height: 24,
    },
    toggleServiceButton: {
      margin: theme.spacing(0.5),
      borderRadius: 20,
      height: 48,
    },
    ul: {
      display: "flex",
      // justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
  })
);

export default function HomeView() {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedService, setSelectedService] = useState<string>("");

  return (
    <div className={classes.root}>
      <React.Fragment>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Meine Gruppen</Typography>
            </Grid>
            {/* CreateGroupCard */}
            <Grid item xs={6} md={6} lg={6}>
              <CreateGroupCard />
            </Grid>
            {/* ListGroupsCard */}
            <Grid item xs={6} md={6} lg={6}>
              <ListGroupsCard />
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
      <React.Fragment>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Vorgeschlagene Gruppen</Typography>
            </Grid>
            {/*<CategoriesList*/}
            {/*  selectedCategory={selectedCategory}*/}
            {/*  setSelectedCategory={setSelectedCategory}*/}
            {/*/>*/}
            {/*{selectedCategory && (*/}
            {/*  <ServicesList*/}
            {/*    selectedCategory={selectedCategory}*/}
            {/*    selectedService={selectedService}*/}
            {/*    setSelectedService={setSelectedService}*/}
            {/*  />*/}
            {/*)}*/}
            <GroupsGrid />
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}

import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core";

interface ServiceLoginData {
  email: string;
  isOwner: boolean;
  tos: boolean;
}

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: "2rem",
  },
  form: {
    marginBottom: theme.spacing(2),
  },
}));

export default function NewGroupConfirm() {
  const classes = useStyles();

  const {
    reset,
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const onSubmit = async (data: ServiceLoginData) => {
    console.log("data", data);
  };

  console.log(getValues());

  // useEffect(() => {
  //   reset({ ...formContent.three });
  // }, [formContent.three, reset]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Zugangsdaten
      </Typography>
      {getValues("visibility") === "public" ? (
        <div>
          <Typography variant="body1" gutterBottom>
            Damit die anderen Mitglieder Zugang zu zum Dienst erhalten,
            benötigen sie die Zugangsdaten.
          </Typography>

          <section className={classes.marginTop}>
            <Typography variant="h6" gutterBottom>
              Tippe die E-Mail-Adresse für den Service ein
            </Typography>
            <form className={classes.form}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    {...register("email", {
                      required: true,
                      shouldUnregister: true,
                    })}
                    error={errors.email ? true : false}
                    helperText={errors.email && "E-Mail wird benötigt!"}
                    label="E-Mail Adresse"
                    variant="filled"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
            <section className={classes.marginTop}>
              <Typography variant="h6" gutterBottom>
                Ändere dein Passwort
              </Typography>
              <Typography variant="body1" gutterBottom>
                Bei Zazmo steht Sicherheit an erster Stelle, deshalb wollen wir
                nicht das du dein aktuelles Passwort hier eintippst. Um eine
                hohe Sicherheit zu gewähren, bitten wir dich dein aktuelles
                Passwort in ein neues, von unserem Sicherheitsalgorithmus
                erstelltes Passwort zu ändern. Dieses Passwort lassen wir dir
                demnächst zukommen.
              </Typography>
            </section>
          </section>
        </div>
      ) : (
        <div>
          <Typography variant="body1" gutterBottom>
            Teile deine Zugangsdaten mit deinen Freunden, wenn Sie in die Gruppe
            eintreten. <br />
            <br /> Aus Sicherheitsgründen wollen wir nicht deine Zugangsdaten
            bei Zazmo speichern.
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
} from "@material-ui/core";
import { theme } from "../../../Theme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormContext } from "react-hook-form";
import { SepaPay } from "../../../interfaces/payment.interface";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  media: {
    height: 23,
  },
  accordion: {
    "&:last-child": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      borderBottomLeftRadius: "16px",
      borderBottomRightRadius: "16px",
    },
    "&:first-child": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      borderBottomLeftRadius: "16px",
      borderBottomRightRadius: "16px",
    },
  },
  form: {
    marginBottom: theme.spacing(2),
  },
});

export default function SepaPayAccordion({ expanded, handleChange }: any) {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext<SepaPay>();

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "sepa"}
        onChange={handleChange("sepa")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="credit-content"
          id="credit-header"
        >
          <Typography variant="h6">
            SEPA-Lastschrift
            <img className={classes.media} src="/images/sepa.png" alt="sepa" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register("ownerName", { required: expanded })}
                error={!!errors.ownerName}
                helperText={
                  errors.ownerName && "Kontoinhaber wird benötigt!"
                }
                label="Kontoinhaber"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("iban", { required: expanded })}
                error={!!errors.iban}
                helperText={errors.iban && "IBAN wird benötigt!"}
                label="IBAN"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="bic"
                {...register("bic", { required: expanded })}
                error={errors.bic ? true : false}
                helperText={errors.bic && "BIC wird benötigt!"}
                label="BIC"
                variant="filled"
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

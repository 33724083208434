import { createContext } from "react";
import { UserState, initialUserState } from "./userState";
import {NewUserDTO} from "../../interfaces/user.interface";

export interface UserContextInterface extends UserState {
  registerUser: (newUser: NewUserDTO) => Promise<void>;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error("You forgot to wrap your component in <UserProvider>.");
};

/**
 * @ignore
 */
const initialContext = {
  ...initialUserState,
  registerUser: stub,
};

/**
 * The User Context
 */
const UserContext = createContext<UserContextInterface>(initialContext);

export default UserContext;

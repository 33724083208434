import { Box, Typography } from "@material-ui/core";
import React from "react";

export function OnboardingHeader() {
  return (
    <Typography component="div">
      <Box fontSize="h5.fontSize" textAlign="center" m={1}>
        Registrierung vervollständigen
      </Box>
    </Typography>
  );
}

import {useCopyToClipboard} from "../hooks/use-copy-to-clipboard";
import {IconButton, InputAdornment, Snackbar, TextField} from "@material-ui/core";
import {FileCopy} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import React from "react";

interface TextFieldToClipboardProps {
  text: string;
}

export const TextFieldToClipboard = ({text}: TextFieldToClipboardProps) => {
  const [copyUrlStatus, copyUrl] = useCopyToClipboard(text);

  return (
    <div>
      <TextField
        value={text}
        variant="filled"
        size="small"
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="copy to clipboard"
                onClick={copyUrl}
              >
                <FileCopy />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={copyUrlStatus === 'copied'}
        autoHideDuration={6000}
      >
        <Alert severity="success">In der Ablage gespeichert!</Alert>
      </Snackbar>
    </div>

  )
}
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Chip, Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { theme } from "../../../Theme";
import Typography from "@material-ui/core/Typography";
import { Group } from "../../../interfaces/group.interface";

const useStyles = makeStyles({
  root: {
    borderRadius: "16px",
    padding: theme.spacing(2),
  },
  avatar: {
    [theme.breakpoints.down("sm")]: {
      width: 30,
      height: 30,
      fontSize: 12,
    },
    color: theme.palette.getContrastText("#F57D7E"),
    backgroundColor: "#F57D7E",
  },
  slot: {
    width: 25,
    height: 5,
  },
  link: {
    color: "#F57D7E",
    textDecoration: "none",
  },
  media: {
    height: 21,
  },
});

export default function GroupCard(group: Group) {
  const classes = useStyles();

  const chips = [];

  for (let i = 0; i < group.availableSlots; i++) {
    if (i < group.availableSlots) {
      chips.push(<Chip className={classes.slot} key={i} />);
    } else {
      chips.push(<Chip className={classes.slot} color="primary" key={i} />);
    }
  }

  return (
    <Link to={`/group-details/${group.id}`} className={classes.link}>
      <Paper className={classes.root}>
        <Grid container spacing={1}>
          <Grid container justifyContent={"center"} item xs={12}>
            <img
              className={classes.media}
              src={group.service.logos.imageUrl}
              alt={group.service.name}
            />
          </Grid>
          <Grid item container spacing={1} direction="row">
            <Grid item xs={3}>
              <Avatar className={classes.avatar}>
                {group.members[0].name.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item xs={6} container>
              <Typography variant="subtitle1">
                {group.members[0].name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              container
              direction="column"
              alignItems="flex-end"
            >
              <Typography variant="caption">
                {group.members[0].rating}
              </Typography>
              <Typography variant="caption">
                ({group.members[0].totalRatings})
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="row">
            <Grid
              item
              xs={6}
              container
              direction="column"
              alignItems="flex-start"
            >
              <Typography variant="caption">{group.service.name}</Typography>
              <Typography variant="caption">{group.service.type}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              direction="column"
              alignItems="flex-end"
            >
              <Typography variant="caption">
                {(group.service.price.amount / 100)
                  .toString()
                  .replace(".", ",")}{" "}
                €
              </Typography>
              <Typography variant="caption">im Monat</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {chips.reverse()}
          </Grid>
          <Grid item xs={12} container direction="column" alignItems="flex-end">
            <Typography variant="caption">
              {group.availableSlots} Slot{group.availableSlots > 1 ? "s" : ""}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  );
}

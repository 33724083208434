import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import GroupDescriptionCard from "./GroupDescriptionCard";
import OrderHistoryCard from "./OrderHistoryCard";
import PaymentMethodForm from "./PaymentMethodForm";
import { Group } from "../../../interfaces/group.interface";

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: theme.spacing(2),
  },
}));

interface JoinGroupSignupProps {
  group: Group;
  joinGroupSuccess: () => void;
}

export default function JoinGroupSignup({
  group,
  joinGroupSuccess,
}: JoinGroupSignupProps) {
  const classes = useStyles();

  useEffect(() => {
    console.log(`group: ${group.id}`);
  }, [group]);

  return (
    <React.Fragment>
      <div className={classes.card}>
        <GroupDescriptionCard
          name={group.name}
          service={group.service}
          members={group.members}
          availableSlots={group.availableSlots}
        />
      </div>

      <div className={classes.card}>
        <OrderHistoryCard
          totalPrice={group.service.price.amount}
          buyerProtectionFee={group.service.fees.amount}
        />
      </div>
      <PaymentMethodForm onSubmitSuccess={joinGroupSuccess} id={group.id} />
    </React.Fragment>
  );
}

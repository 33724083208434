import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

const mode = "dark";

const mainPrimaryColor = mode === "dark" ? "#f57d7e" : "#1976d2";
const mainSecondaryColor = mode === "dark" ? "#ffffff" : "#dc004e";
const backgroundColor = mode === "dark" ? "#121212" : "#fafafa";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
    // h1: {
    //   fontSize: "5rem",
    //   fontWeight: 700,
    // },
    // h2: {
    //   fontSize: "3.75rem",
    //   fontWeight: 700,
    // },
    // h3: {
    //   fontSize: "1.875rem",
    //   fontWeight: 700,
    // },
    // h4: {
    //   fontSize: "2rem",
    //   fontWeight: 600,
    // },
    // h5: {
    //   fontSize: "1.5rem",
    //   fontWeight: 600,
    // },
    // h6: {
    //   fontSize: "1.125rem",
    //   fontWeight: 500,
    // },
    // body1: {
    //   fontSize: "1rem",
    //   fontStyle: "normal",
    //   fontWeight: 400,
    // },
    // body2: {
    //   fontSize: "0.75rem",
    //   fontStyle: "normal",
    //   fontWeight: 400,
    // },
    caption: {
      color: "#AFAFAF",
    },
  },
  palette: {
    type: mode,
    primary: {
      main: mainPrimaryColor,
      contrastText: "#ffffff",
    },
    secondary: {
      main: mainSecondaryColor,
    },
    action: {
      disabled: "#ffffff",
      disabledBackground: "#934b4c",
    },
    background: {
      default: backgroundColor,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "16px",
        height: "44px",
      },
      sizeSmall: {
        height: "32px",
        fontSize: "0.7rem",
      },
    },
    MuiSelect: {
      filled: {
        "&:focus": {
          backgroundColor: "transparent",
          color: "#fff",
        },
      },
    },
    MuiPopover: {
      paper: {
        top: "425px",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#2F2F2F",
      }
    },
    MuiFilledInput: {
      input: {
        padding: "22px 16px 6px",
      },
      multiline: {
        padding: "22px 16px 6px",
      },
      root: {
        color: "#ffffff",
        fontSize: "0.875rem",
        fontWeight: 400,
        backgroundColor: "#312d2d",
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",

        "&$focused": {
          backgroundColor: "#312d2d",
          border: "1px solid #f57d7e",
        },
        "&:hover": {
          backgroundColor: "#312d2d",
        },
        "&$error": {
          border: "1px solid red",
        },
      },
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
        "&&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "21px",
      },
      filled: {
        transform: "translate(16px, 12px) scale(1)",
        color: "#fff",
        "&$focused": {
          color: "#fff",
        },
        "&$shrink": {
          transform: "translate(16px, 4px) scale(0.75)",
        },
      },
    },
    MuiFormLabel: {
      root: {},
    },
  },
});

export { theme };

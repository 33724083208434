import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
// import CreditCardAccordion from "./CreditCardAccordion";
import SepaPayAccordion from "./SepaPayAccordion";
import {useForm, FormProvider, Controller} from "react-hook-form";
import VerifiedUser from "@material-ui/icons/VerifiedUserOutlined";
import React, {useEffect} from "react";
import {NewPaymentForm, NewPaymentDTO} from "../../../interfaces/payment.interface";
import {Alert} from "@material-ui/lab";
import useHttp from "../../../hooks/use-http";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    // padding: 5,
    // paddingLeft: theme.spacing(1),
  },
}));

interface PaymentMethodFormProps {
  id: string;
  onSubmitSuccess: () => void;
}

const defaultValues = {
  tosZazmo: false,
  tosService: false,
};

export default function PaymentMethodForm({id, onSubmitSuccess}: PaymentMethodFormProps) {
  const methods = useForm<NewPaymentForm>({mode: "all"});
  const {
    control,
    getValues,
    watch,
    handleSubmit,
    formState: {errors, isValid},
  } = methods;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string>("sepa");
  const {
    response,
    isLoading,
    error,
    sendRequest: sendPaymentRequest,
  } = useHttp();

  const form = watch();

  const handleChange = (panel: string) => () => {
    // setExpanded(panel);
  };

  const createPayment = (paymentData: any) => {
    console.log(paymentData);
    if (paymentData) {
      window.open(paymentData.RedirectURL,"_self" );
    }
    onSubmitSuccess();

  };

  const onSubmit = () => {
    console.log("submit");
    const newPayment: NewPaymentDTO = {
      iban: getValues("iban"), ownerName: getValues("iban"), ownerAddress: 'Musterstr', returnURL: `${window.location.href}?isSuccess=true`
    };

    console.log("new payment", newPayment);

    sendPaymentRequest(
      {url: `rest/v1/sharing-groups/join/${id}`, method: "post", data: newPayment},
      createPayment
    ).then(() => {
      // redirect
    });
  };

  useEffect(() => {
    console.log("FORM CONTEXT", form, errors);
  }, [errors, form]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">Zahlungsdaten</Typography>
          </Grid>
          {/*<Grid item>*/}
          {/*  <CreditCardAccordion*/}
          {/*    expanded={expanded}*/}
          {/*    handleChange={handleChange}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item>
            <SepaPayAccordion expanded={expanded} handleChange={handleChange}/>
          </Grid>
          {/*<Grid item container direction="column" spacing={0}>*/}
          {/*  <Grid item>*/}
          {/*    <Controller*/}
          {/*      name="tosZazmo"*/}
          {/*      control={control}*/}
          {/*      defaultValue={false}*/}
          {/*      rules={{ required: true }}*/}
          {/*      render={({ field }) => (*/}
          {/*        <FormControlLabel*/}
          {/*          control={*/}
          {/*            <Checkbox*/}
          {/*              onChange={(e: ChangeEvent<HTMLInputElement>) => field.onChange(e.target.checked)}*/}
          {/*              checked={field.value}*/}
          {/*              className={classes.checkbox}*/}
          {/*            />*/}
          {/*          }*/}
          {/*          label="AGBs Zazmo"*/}
          {/*        />*/}
          {/*      )} // props contains: onChange, onBlur and value*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*  <Grid item>*/}
          {/*    <Controller*/}
          {/*      name="tosService"*/}
          {/*      control={control}*/}
          {/*      defaultValue={false}*/}
          {/*      rules={{ required: true }}*/}
          {/*      render={({ field }) => (*/}
          {/*        <FormControlLabel*/}
          {/*          control={*/}
          {/*            <Checkbox*/}
          {/*              onChange={(e: ChangeEvent<HTMLInputElement>) => field.onChange(e.target.checked)}*/}
          {/*              checked={field.value}*/}
          {/*              className={classes.checkbox}*/}
          {/*            />*/}
          {/*          }*/}
          {/*          label="AGBs Streaming Anbieter"*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          <Grid item>
            <Button disabled={!isValid} fullWidth variant="contained" color="primary" type="submit">
              Jetzt beitreten
            </Button>
          </Grid>
          <Grid container direction="row" spacing={1} justifyContent="center">
            <Grid item>
              <VerifiedUser color="error" fontSize="small"/>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="error">
                Zahlungen sind sicher verschlüsselt
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {/*<Snackbar*/}
      {/*  anchorOrigin={{ vertical: "top", horizontal: "center" }}*/}
      {/*  open={errors.tosZazmo || errors.tosService ? true : false}*/}
      {/*  autoHideDuration={6000}*/}
      {/*>*/}
      {/*  <Alert severity="error">Bitte bestätige die AGB's!</Alert>*/}
      {/*</Snackbar>*/}
    </FormProvider>
  );
}

import { OnboardingHeader } from "./components/OnboardingHeader";
import { OnboardingAvatar } from "./components/OnboardingAvatar";
import { OnboardingForm } from "./components/OnboardingForm";
import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default function OnboardingView() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <main className={classes.layout}>
        <OnboardingHeader />
        <OnboardingAvatar />
        <OnboardingForm />
      </main>
    </React.Fragment>
  );
}

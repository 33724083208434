import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { theme } from "../../../Theme";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    borderRadius: "16px",
    height: 200,
  },
});

export default function ListGroupsCard() {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="caption" align="center">
        Hier sind die existierenden Gruppen aufgelistet. Karte wird derzeit
        designt
      </Typography>
    </Paper>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Star from "@material-ui/icons/Star";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  ButtonBase,
  Chip,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import { theme } from "../../../Theme";
import { months } from "../../../utils/months";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "16px",
  },
  image: {
    width: 64,
    height: 64,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  avatar: {
    width: 64,
    height: 64,
    color: theme.palette.getContrastText("#F57D7E"),
    backgroundColor: "#F57D7E",
  },
  boldFont: {
    fontWeight: "bold",
  },
  marginTop: {
    marginTop: 12,
  },
  slot: {
    width: 25,
    height: 5,
  },
});

interface AdminDetailsCardProps {
  avatar?: string;
  name: string;
  rating: number;
  totalRatings: number;
  trustLevel: number;
  accountCreated: Date;
}

export default function AdminDetailsCard(admin: AdminDetailsCardProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase className={classes.image}>
              <Avatar className={classes.avatar}>{admin.name.charAt(0)}</Avatar>
            </ButtonBase>
          </Grid>
          <Grid item xs={7} container>
            <Grid item xs container direction="column" spacing={2}>
              <Typography variant="h6">
                {admin.name}{" "}
                <Chip
                  icon={<Star />}
                  label={admin.rating}
                  color="primary"
                  size="small"
                />
              </Typography>
              <Typography variant="caption">Gruppen-Admin</Typography>
              <Typography variant="caption">
                User seit {months[admin.accountCreated.getMonth()]}{" "}
                {admin.accountCreated.getFullYear().toString()}
              </Typography>
              <Typography variant="caption">
                Trust Level: {admin.trustLevel}%
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <IconButton aria-label="follow">
              <ArrowForwardIos fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CategorySelection from "./CategorySelection";
import useHttp from "../../../hooks/use-http";
import { Service } from "../../../interfaces/service.interface";
import { Dialog } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  categoryGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  favoriteSelect: {
    background: "#2F2F2F",
    border: 0,
    borderRadius: 16,
    width: "100%",
  },
  categorySelect: {
    background: "#2F2F2F",
    color: "#ffffff",
    border: 0,
    borderRadius: 16,
    width: "100%",
  },
  media: {
    height: 21,
  },
}));

export default function NewGroupCategory() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const selectRef = useRef<HTMLInputElement>();

  const methods = useFormContext();

  const { control, reset, register, setValue, watch } = methods;

  const { isLoading, error, sendRequest: fetchServices } = useHttp();

  useEffect(() => {
    const transformServices = (services: Service[]) => {
      // TODO: remove if when marvin has coded a proper response
      if (services) setServices(services);
    };
    fetchServices({ url: "/rest/v1/service-provider" }, transformServices);
  }, [fetchServices]);

  useEffect(() => {
    register("serviceID");
  }, [register]);

  // useEffect(() => {
  //   reset({ ...formContent.one });
  // }, [formContent.one, reset]);

  const setService = (service: Service) => {
    // console.log(service.id);
    setSelectedService(service);
    setValue("serviceID", service.id);
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Wähle einen Service
      </Typography>
      <Typography variant="body1" gutterBottom>
        Definiere die Kategorie deiner Sharing Gruppe damit wir die Gruppe den
        richtigen Leuten zeigen können.
      </Typography>
      <div className={classes.categoryGrid}>
        <Typography variant="h5" gutterBottom>
          Unsere Topprodukte
        </Typography>
        <Grid container spacing={1}>
          {isLoading && <div>Loading...</div>}
          {error && <div>Error, the backend moved to the dark side.</div>}
          {services.map((service) => (
            <Grid item key={service.name} xs={6}>
              <ToggleButton
                name="service"
                className={classes.favoriteSelect}
                disableRipple
                value={service.name}
                selected={service === selectedService}
                onChange={() => {
                  setService(service);
                }}
              >
                <img
                  className={classes.media}
                  src={service.logos.imageUrl}
                  alt={service.name}
                />
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </div>
      {/*<Typography variant="h6" gutterBottom>*/}
      {/*  Weitere Kategorien*/}
      {/*</Typography>*/}
      {/*<TextField*/}
      {/*  id="selected-service"*/}
      {/*  // {...register("service", { required: true })}*/}
      {/*  inputRef={(input) => input && input.blur()}*/}
      {/*  variant="filled"*/}
      {/*  value={selectedService ? selectedService.name : ""}*/}
      {/*  onClick={() => setOpen(true)}*/}
      {/*  // onChange={(event) => console.log(event)}*/}
      {/*  label="Kategorie"*/}
      {/*  fullWidth*/}
      {/*  InputProps={{ endAdornment: <KeyboardArrowDownIcon /> }}*/}
      {/*/>*/}

      <Dialog
        fullScreen
        keepMounted={false}
        open={open}
        onClose={() => {
          console.log(selectRef.current);
          selectRef.current!.blur();
          setOpen(false);
        }}
        PaperProps={{
          style: {
            backgroundColor: "#121212",
          },
        }}
      >
        <CategorySelection
          services={services}
          setOpen={setOpen}
          setService={setService}
        />
      </Dialog>
    </React.Fragment>
  );
}

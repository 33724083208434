import {
  CssBaseline,
  CircularProgress,
  ThemeProvider,
  Grid,
} from "@material-ui/core";
import React from "react";
import { theme } from "../Theme";

export default function Loading() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    </ThemeProvider>
  );
}

import React from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Group } from "../../../interfaces/group.interface";
import { GroupDetailsState } from "../GroupDetailsView";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: "calc(100vh - 276px)",
    [theme.breakpoints.up("sm")]: {
      minHeight: "unset",
    },
    padding: theme.spacing(2),
  },
  media: {
    height: 100,
    margin: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContentText: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    textAlign: "center",
    alignItems: "center",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface AccessInfoProps {
  group: Group;
  setGroupDetailsState: (state: GroupDetailsState) => void;
}

export default function GroupDetailsAccessInfo(props: AccessInfoProps) {
  const classes = useStyles();

  const back = () => {
    props.setGroupDetailsState(GroupDetailsState.groupInfo);
  };

  return (
    <React.Fragment>
      <div className={classes.content}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <IconButton aria-label="follow" onClick={back}>
              <ArrowBackIos fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography variant="h5">Zugangsdaten</Typography>
          </Grid>
          {props.group.isPrivate ? (
            <Grid item>
              <Typography variant="subtitle1">
                Kontaktiere den Admin der Gruppe, um die Zugangsinformationen
                zum Service zu erhalten.
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="h6">
                    E-Mail-Adresse des Admins
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="eMail"
                    label="E-Mail Addresse"
                    // defaultValue={props.group.members[0].name}
                    variant="filled"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs>
                <Typography>Passwort</Typography>
              </Grid>
              <Grid item xs>
                <Typography>D3849388499DF</Typography>
              </Grid>{" "}
            </>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
}

export const birthdayFormat = (val: string) => {
  let date = limit(val.substring(0, 2), '31');
  let month = limit(val.substring(2, 4), '12');
  let year = limit(val.substring(4, 8), new Date().getFullYear().toString());

  return date + (month.length ? '.' + month : '') + (year.length ? '.' + year : '');
}


export const limit = (val: string, max: string) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val.toString();
  }
  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }
  return val;
}
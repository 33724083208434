import { Box, makeStyles } from "@material-ui/core";

interface GroupDetailsLogoProps {
  title: string;
  src: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 140,
    borderBottom: "1px white solid",
  },
  media: {
    height: 51,
  },
}));

export default function GroupDetailsLogo(props: GroupDetailsLogoProps) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img className={classes.media} src={props.src} alt={props.title} />
    </Box>
  );
}

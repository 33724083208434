import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Paper } from "@material-ui/core";
import { theme } from "../../../Theme";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "16px",
  },
  image: {
    width: 64,
    height: 64,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  avatar: {
    width: 64,
    height: 64,
    color: theme.palette.getContrastText("#F57D7E"),
    backgroundColor: "#F57D7E",
  },
  boldFont: {
    fontWeight: "bold",
  },
  marginTop: {
    marginTop: 12,
  },
  slot: {
    width: 25,
    height: 5,
  },
});

interface NextDebitCardProps {
  nextBilling: Date;
}

export default function NextDebitCard(props: NextDebitCardProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={10} container>
            <Grid item xs container direction="column">
              <Grid item>
                <Typography variant="h6">Nächste Abbuchung</Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle1" color="primary">
                  {("0" + props.nextBilling.getDate()).slice(-2) + "."}
                  {("0" + props.nextBilling.getMonth()).slice(-2) + "."}
                  {props.nextBilling.getFullYear().toString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <IconButton aria-label="follow">
              <ArrowForwardIos fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

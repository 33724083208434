import { UserState, User } from "./userState";

type Action =
  | {
      type: "INITIALISED" | "USER_UPDATED";
      user?: User;
    }
  | { type: "ERROR"; error: Error };

/**
 * Handles how that state changes in the `useUser` hook.
 */
export const reducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case "INITIALISED":
      return {
        ...state,
        isOnboarded: !!action.user,
        user: action.user,
        isLoading: false,
        error: undefined,
      };
    case "USER_UPDATED":
      return {
        ...state,
        user: action.user,
      };
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
  }
};

export const subcategories = ["Audio", "Video", "Gaming", "Sport"];

export const mainCategories = [
  "Entertainment",
  "Arbeit",
  "Wohnung",
  "Weiterbildung",
  "Apps",
  "Mobilität",
  "Fitness",
  "Custom",
];

export const categoryEntertainment = [
  "Video-Streaming",
  "Music-Streaming",
  "Sport-Streaming",
  "Gaming",
];

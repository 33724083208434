import axios from "axios";
// import handleError from "./httpError";

// axios.defaults.baseURL = 'http://localhost:3000/'
// axios.defaults.headers.common = {'Authorization': `bearer ${token}`}

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
});

export const authorized = (token: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return instance;
};

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    return Promise.reject(error);
  }
);

// const SERVER_DOMAIN = "http://localhost:3000"; // process.env.SERVER_DOMAIN;
// const getHeaders = (token?: string) => {
//   return {
//     headers: {
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   };
// };
// // HTTP GET Request - Returns Resolved or Rejected Promise
// export const get = (path: string, token?: string) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${SERVER_DOMAIN}${path}`, getHeaders(token))
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(handleError(error));
//       });
//   });
// };
// // HTTP PATCH Request - Returns Resolved or Rejected Promise
// export const patch = (path: string, data: any) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .patch(`${SERVER_DOMAIN}${path}`, data, getHeaders())
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(handleError(error));
//       });
//   });
// };
// // HTTP POST Request - Returns Resolved or Rejected Promise
// export const post = (path: string, data: any) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${SERVER_DOMAIN}${path}`, data, getHeaders())
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(handleError(error));
//       });
//   });
// };
// // HTTP DELETE Request - Returns Resolved or Rejected Promise
// export const del = (path: string) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .delete(`${SERVER_DOMAIN}${path}`, getHeaders())
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(handleError(error));
//       });
//   });
// };

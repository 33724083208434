import { LinearProgress } from "@material-ui/core";
import { ComponentType, FC } from "react";
import { Redirect, useHistory } from "react-router-dom";
import useUser from "../../context/User/useUser";

const withOnboarding = <P extends object>(
  Component: ComponentType<P>
): FC<P> => {
  return function WithOnboarding(props: P): JSX.Element {
    const history = useHistory();
    const isOnboarding = history.location.pathname === "/onboarding";
    const { isLoading, isOnboarded } = useUser();

    return isLoading ? (
      <LinearProgress />
    ) : !isOnboarded && !isOnboarding ? (
      <Redirect to="/onboarding" />
    ) : isOnboarded && isOnboarding ? (
      <Redirect to="/" />
    ) : (
      <Component {...props} />
    );
  };
};

export default withOnboarding;

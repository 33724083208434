import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import OndemandVideo from "@material-ui/icons/OndemandVideo";
import LockOpen from "@material-ui/icons/LockOpen";
import { Avatar, Grid, IconButton, Paper } from "@material-ui/core";
import { theme } from "../../../Theme";
import { Service } from "../../../interfaces/service.interface";
import { months } from "../../../utils/months";
import { Member } from "../../../interfaces/member.interface";

interface GroupDetailsCardProps {
  name: string;
  isPrivate: boolean;
  service: Service;
  description: string;
  groupCreated: Date;
  members: Member[];
}

const useStyles = makeStyles({
  root: {
    minHeight: 200,
    borderRadius: "16px",
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    borderRadius: "16px",
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: 12,
    color: theme.palette.getContrastText("#F57D7E"),
    backgroundColor: "#F57D7E",
  },
  boldFont: {
    fontWeight: "bold",
  },
  marginTop: {
    marginTop: 12,
  },
  slot: {
    width: 25,
    height: 5,
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  description: {
    marginTop: theme.spacing(1),
  },
});

export default function GroupDetailsCard(group: GroupDetailsCardProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <IconButton aria-label="follow" className={classes.closeButton}>
        <MoreHoriz fontSize="large" />
      </IconButton>
      <Typography variant="h6">{group.name}</Typography>
      <Typography gutterBottom variant="subtitle2">
        Erstellt im {months[group.groupCreated.getMonth()]}{" "}
        {group.groupCreated.getFullYear().toString()}
      </Typography>
      <Grid container spacing={1} direction="row">
        <Grid item>
          <LockOpen fontSize="small" />
        </Grid>
        <Grid item>
          <Typography variant="caption">
            {group.isPrivate ? "Privat" : "Öffentlich"}e Gruppe
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} direction="row">
        <Grid item>
          <OndemandVideo fontSize="small" color="primary" />
        </Grid>
        <Grid item>
          <Typography variant="caption" color="primary">
            {group.service.name} {group.service.type}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} direction="row">
        {group.members.map((member) => {
          return (
            <Grid key={member.id} item>
              <Avatar className={classes.avatar}>
                {member.name.charAt(0)}
              </Avatar>
            </Grid>
          );
        })}
      </Grid>

      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.description}
      >
        {group.description}
      </Typography>
    </Paper>
  );
}

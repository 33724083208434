import { Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircleOutline } from "@material-ui/icons";
import { theme } from "../../../Theme";

const useStyles = makeStyles({
  root: {
    height: 200,
    color: "#F57D7E",
    borderRadius: "16px",
    border: "2px dashed #F57D7E",
    backgroundColor: "transparent",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignitems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  link: {
    color: "#F57D7E",
    textDecoration: "none",
  },
});

export default function CreateGroupCard() {
  const classes = useStyles();

  return (
    <Link to="/new-group" className={classes.link}>
      <Paper className={classes.root}>
        <Typography gutterBottom variant="caption" align="center">
          Erstelle jetzt deine erste eigene Gruppe.
        </Typography>
        <AddCircleOutline
          style={{
            fontSize: 60,
            width: "unset",
          }}
        />
      </Paper>
    </Link>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Paper } from "@material-ui/core";
import { theme } from "../../../Theme";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: "16px",
    backgroundColor: theme.palette.primary.main,
  },
});

interface PaymentInfoButtonProps {
  onClick: () => void;
}

export default function PaymentInfoButton(props: PaymentInfoButtonProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={10} container>
            <Grid item container alignContent="center">
              <Typography variant="h6">Zugangsinformationen</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <IconButton aria-label="follow" onClick={props.onClick}>
              <ArrowForwardIos fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    large: {
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  })
);

export function OnboardingAvatar() {
  const { user } = useAuth0();
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" p={3}>
      <Avatar alt={user?.name} src={user?.picture} className={classes.large} />
    </Box>
  );
}

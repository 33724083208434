export const nationalities = [
  { nationalityID: 1, countryCode: "GB", nationality: "British" },
  { nationalityID: 34, countryCode: "AF", nationality: "Afghan" },
  { nationalityID: 35, countryCode: "AL", nationality: "Albanian" },
  { nationalityID: 36, countryCode: "DZ", nationality: "Algerian" },
  { nationalityID: 158, countryCode: "US", nationality: "American" },
  { nationalityID: 38, countryCode: "AD", nationality: "Andorran" },
  { nationalityID: 39, countryCode: "AO", nationality: "Angolan" },
  { nationalityID: 40, countryCode: "AM", nationality: "Armenian" },
  { nationalityID: 41, countryCode: "AT", nationality: "Austrian" },
  { nationalityID: 42, countryCode: "AZ", nationality: "Azerbaijani" },
  { nationalityID: 2, countryCode: "AR", nationality: "Argentinian" },
  { nationalityID: 3, countryCode: "AU", nationality: "Australian" },
  { nationalityID: 43, countryCode: "BH", nationality: "Bahraini" },
  { nationalityID: 44, countryCode: "BD", nationality: "Bangladeshi" },
  { nationalityID: 45, countryCode: "BB", nationality: "Barbadian" },
  { nationalityID: 46, countryCode: "BY", nationality: "Belarusian" },
  { nationalityID: 47, countryCode: "BZ", nationality: "Belizean" },
  { nationalityID: 48, countryCode: "BJ", nationality: "Beninese" },
  { nationalityID: 49, countryCode: "BM", nationality: "Bermudian" },
  { nationalityID: 50, countryCode: "BT", nationality: "Bhutanese" },
  { nationalityID: 51, countryCode: "BO", nationality: "Bolivian" },
  { nationalityID: 52, countryCode: "BA", nationality: "Bosnian" },
  { nationalityID: 53, countryCode: "BW", nationality: "Botswanan" },
  { nationalityID: 54, countryCode: "BG", nationality: "Bulgarian" },
  { nationalityID: 55, countryCode: "BF", nationality: "Burkinese" },
  { nationalityID: 56, countryCode: "BI", nationality: "Burundian" },
  { nationalityID: 7, countryCode: "CA", nationality: "Canadian" },
  { nationalityID: 8, countryCode: "CN", nationality: "Chinese" },
  { nationalityID: 9, countryCode: "CO", nationality: "Colombian" },
  { nationalityID: 10, countryCode: "CU", nationality: "Cuban" },
  { nationalityID: 57, countryCode: "KH", nationality: "Cambodian" },
  { nationalityID: 58, countryCode: "CM", nationality: "Cameroonian" },
  { nationalityID: 59, countryCode: "CV", nationality: "Cape Verdean" },
  { nationalityID: 60, countryCode: "TD", nationality: "Chadian" },
  { nationalityID: 61, countryCode: "CL", nationality: "Chilean" },
  { nationalityID: 62, countryCode: "CG", nationality: "Congolese" },
  { nationalityID: 63, countryCode: "CR", nationality: "Costa Rican" },
  { nationalityID: 64, countryCode: "HR", nationality: "Croat" },
  { nationalityID: 65, countryCode: "CY", nationality: "Cypriot" },
  { nationalityID: 66, countryCode: "CZ", nationality: "Czech" },
  { nationalityID: 67, countryCode: "DK", nationality: "Danish" },
  { nationalityID: 11, countryCode: "DO", nationality: "Dominican" },
  { nationalityID: 68, countryCode: "DJ", nationality: "Djiboutian" },
  { nationalityID: 69, countryCode: "DM", nationality: "Dominican" },
  { nationalityID: 26, countryCode: "NL", nationality: "Dutch" },
  { nationalityID: 12, countryCode: "EC", nationality: "Ecuadorean" },
  { nationalityID: 70, countryCode: "EG", nationality: "Egyptian" },
  { nationalityID: 71, countryCode: "ER", nationality: "Eritrean" },
  { nationalityID: 72, countryCode: "EE", nationality: "Estonian" },
  { nationalityID: 73, countryCode: "ET", nationality: "Ethiopian" },
  { nationalityID: 74, countryCode: "FJ", nationality: "Fijian" },
  { nationalityID: 75, countryCode: "FI", nationality: "Finnish" },
  { nationalityID: 76, countryCode: "PF", nationality: "French Polynesian" },
  { nationalityID: 14, countryCode: "FR", nationality: "French" },
  { nationalityID: 77, countryCode: "GA", nationality: "Gabonese" },
  { nationalityID: 78, countryCode: "GM", nationality: "Gambian" },
  { nationalityID: 79, countryCode: "GE", nationality: "Georgian" },
  { nationalityID: 15, countryCode: "DE", nationality: "Deutsch" },
  { nationalityID: 16, countryCode: "GT", nationality: "Guatemalan" },
  { nationalityID: 80, countryCode: "GH", nationality: "Ghanaian" },
  { nationalityID: 81, countryCode: "GR", nationality: "Greek" },
  { nationalityID: 82, countryCode: "GD", nationality: "Grenadian" },
  { nationalityID: 83, countryCode: "GN", nationality: "Guinean" },
  { nationalityID: 84, countryCode: "GY", nationality: "Guyanese" },
  { nationalityID: 17, countryCode: "HT", nationality: "Haitian" },
  { nationalityID: 18, countryCode: "HN", nationality: "Honduran" },
  { nationalityID: 85, countryCode: "HU", nationality: "Hungarian" },
  { nationalityID: 19, countryCode: "IN", nationality: "Indian" },
  { nationalityID: 20, countryCode: "IE", nationality: "Ireland" },
  { nationalityID: 21, countryCode: "IL", nationality: "Israeli" },
  { nationalityID: 22, countryCode: "IT", nationality: "Italian" },
  { nationalityID: 86, countryCode: "IS", nationality: "Icelandic" },
  { nationalityID: 87, countryCode: "ID", nationality: "Indonesian" },
  { nationalityID: 88, countryCode: "IR", nationality: "Iranian" },
  { nationalityID: 89, countryCode: "IQ", nationality: "Iraqi" },
  { nationalityID: 23, countryCode: "JP", nationality: "Japanese" },
  { nationalityID: 90, countryCode: "JM", nationality: "Jamaican" },
  { nationalityID: 91, countryCode: "JO", nationality: "Jordanian" },
  { nationalityID: 92, countryCode: "KZ", nationality: "Kazakh" },
  { nationalityID: 93, countryCode: "KE", nationality: "Kenyan" },
  { nationalityID: 94, countryCode: "KP", nationality: "North Korean" },
  { nationalityID: 95, countryCode: "KW", nationality: "Kuwaiti" },
  { nationalityID: 96, countryCode: "LV", nationality: "Latvian" },
  { nationalityID: 97, countryCode: "LB", nationality: "Lebanese" },
  { nationalityID: 98, countryCode: "LR", nationality: "Liberian" },
  { nationalityID: 99, countryCode: "LY", nationality: "Libyan" },
  { nationalityID: 100, countryCode: "LT", nationality: "Lithuanian" },
  { nationalityID: 101, countryCode: "LU", nationality: "LUXEMBOURG" },
  { nationalityID: 102, countryCode: "MG", nationality: "Madagascan" },
  { nationalityID: 103, countryCode: "MW", nationality: "Malawian" },
  { nationalityID: 104, countryCode: "MY", nationality: "Malaysian" },
  { nationalityID: 105, countryCode: "MV", nationality: "Maldivian" },
  { nationalityID: 106, countryCode: "ML", nationality: "Malian" },
  { nationalityID: 107, countryCode: "MT", nationality: "Maltese" },
  { nationalityID: 108, countryCode: "MR", nationality: "Mauritanian" },
  { nationalityID: 109, countryCode: "MU", nationality: "Mauritian" },
  { nationalityID: 110, countryCode: "MC", nationality: "Monacan" },
  { nationalityID: 111, countryCode: "MN", nationality: "Mongolian" },
  { nationalityID: 112, countryCode: "ME", nationality: "Montenegrin" },
  { nationalityID: 113, countryCode: "MA", nationality: "Moroccan" },
  { nationalityID: 114, countryCode: "MZ", nationality: "Mozambican" },
  { nationalityID: 25, countryCode: "MX", nationality: "Mexican" },
  { nationalityID: 115, countryCode: "NA", nationality: "Namibian" },
  { nationalityID: 116, countryCode: "NP", nationality: "Nepalese" },
  { nationalityID: 117, countryCode: "NZ", nationality: "New Zealand" },
  { nationalityID: 118, countryCode: "NI", nationality: "Nicaraguan" },
  { nationalityID: 119, countryCode: "NE", nationality: "Nigerien" },
  { nationalityID: 120, countryCode: "NG", nationality: "Nigerian" },
  { nationalityID: 121, countryCode: "NO", nationality: "Norwegian" },
  { nationalityID: 122, countryCode: "OM", nationality: "Omani" },
  { nationalityID: 123, countryCode: "PK", nationality: "Pakistani" },
  { nationalityID: 124, countryCode: "PA", nationality: "Panamanian" },
  { nationalityID: 125, countryCode: "PG", nationality: "Guinean" },
  { nationalityID: 126, countryCode: "PY", nationality: "Paraguayan" },
  { nationalityID: 127, countryCode: "PE", nationality: "Peruvian" },
  { nationalityID: 27, countryCode: "PH", nationality: "Philippine" },
  { nationalityID: 128, countryCode: "PL", nationality: "Polish" },
  { nationalityID: 129, countryCode: "PT", nationality: "Portuguese" },
  { nationalityID: 130, countryCode: "QA", nationality: "Qatari" },
  { nationalityID: 131, countryCode: "RO", nationality: "Romanian" },
  { nationalityID: 132, countryCode: "RW", nationality: "Rwandan" },
  { nationalityID: 13, countryCode: "SV", nationality: "Salvadorean" },
  { nationalityID: 37, countryCode: "AS", nationality: "Samoan" },
  { nationalityID: 133, countryCode: "SA", nationality: "Saudi Arabian" },
  { nationalityID: 134, countryCode: "SN", nationality: "Senegalese" },
  { nationalityID: 135, countryCode: "RS", nationality: "Serbian" },
  { nationalityID: 136, countryCode: "SL", nationality: "Sierra Leonian" },
  { nationalityID: 137, countryCode: "SG", nationality: "Singaporean" },
  { nationalityID: 138, countryCode: "SK", nationality: "Slovak" },
  { nationalityID: 139, countryCode: "SI", nationality: "Slovenian" },
  { nationalityID: 140, countryCode: "SB", nationality: "Slomoni" },
  { nationalityID: 141, countryCode: "SO", nationality: "Somali" },
  { nationalityID: 142, countryCode: "ZA", nationality: "South African" },
  { nationalityID: 24, countryCode: "KR", nationality: "South Korean" },
  { nationalityID: 28, countryCode: "ES", nationality: "Spanish" },
  { nationalityID: 29, countryCode: "SE", nationality: "Swedish" },
  { nationalityID: 30, countryCode: "CH", nationality: "Swiss" },
  { nationalityID: 143, countryCode: "LK", nationality: "Sri Lankan" },
  { nationalityID: 144, countryCode: "SD", nationality: "Sudanese" },
  { nationalityID: 145, countryCode: "SR", nationality: "Surinamese" },
  { nationalityID: 146, countryCode: "SZ", nationality: "Swazi" },
  { nationalityID: 31, countryCode: "TW", nationality: "Taiwanese" },
  { nationalityID: 147, countryCode: "TJ", nationality: "Tajik" },
  { nationalityID: 148, countryCode: "TH", nationality: "Thai" },
  { nationalityID: 149, countryCode: "TG", nationality: "Togolese" },
  { nationalityID: 150, countryCode: "TT", nationality: "Trinidadian" },
  { nationalityID: 151, countryCode: "TN", nationality: "Tunisian" },
  { nationalityID: 152, countryCode: "TR", nationality: "Turkish" },
  { nationalityID: 153, countryCode: "TM", nationality: "Turkoman" },
  { nationalityID: 154, countryCode: "TV", nationality: "Tuvaluan" },
  { nationalityID: 155, countryCode: "UG", nationality: "Ugandan" },
  { nationalityID: 156, countryCode: "UA", nationality: "Ukrainian" },
  { nationalityID: 157, countryCode: "AE", nationality: "Emirati" },
  { nationalityID: 32, countryCode: "VE", nationality: "Venezuelan" },
  { nationalityID: 33, countryCode: "VN", nationality: "Vietnamese" },
  { nationalityID: 159, countryCode: "UY", nationality: "Uruguayan" },
  { nationalityID: 160, countryCode: "UZ", nationality: "Uzbek" },
  { nationalityID: 161, countryCode: "VU", nationality: "Vanuatuan" },
  { nationalityID: 162, countryCode: "YE", nationality: "Yemeni" },
  { nationalityID: 163, countryCode: "ZM", nationality: "Zambian" },
];

import Grid from "@material-ui/core/Grid";
import GroupCard from "./GroupCard";
import useHttp from "../../../hooks/use-http";
import React, {Fragment, useEffect, useState} from "react";
import { Group } from "../../../interfaces/group.interface";
import { Pagination } from "@material-ui/lab";
import useUser from "../../../context/User/useUser";
import useFetch from "../../../hooks/use-fetch";

export default function GroupsGrid() {
  const [groups, setGroups] = useState<Group[]>([]);
  const [pagination, setPagination] = useState({_limit: 4, _page: 1})
  const [totalCount, setTotalCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { user } = useUser();

  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/rest/v1/sharing-groups?userID=${user.userID}&_page=1&_limit=4`;

  const { isLoading, error, sendRequest: fetchGroups } = useHttp();
  // const { status, data, error } = useFetch<Group[]>(url)

  // console.log({ status, data, error })

  useEffect(() => {
    const transformGroups = (groups: Group[]) => {
      console.log(groups);
      setGroups(groups);
    };
    fetchGroups(
      { url: `/rest/v1/sharing-groups?userID=${user.userID}&_page=1&_limit=4` },
      transformGroups
    );
  }, [fetchGroups, itemsPerPage, page, user, user.userID]);

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPagination({ _limit: 4, _page: page })
  };

  return (
    <React.Fragment>
      <Grid item container spacing={1}>
        {isLoading && <div>Loading...</div>}
        {error && <div>Error, the backend moved to the dark side.</div>}
        {groups.map((group) => (
          <Grid item key={group.id} xs={6} sm={4} md={4}>
            <GroupCard {...group} />
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent={"center"}>
        <Pagination page={pagination._page} onChange={handleChange} count={Math.ceil(totalCount / 10)} />
      </Grid>
      {/*<div>{status}</div>*/}
    </React.Fragment>
  );
}
import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import {Controller, useFormContext} from "react-hook-form";
import {theme} from "../../../Theme";

const relationShipItems = [
  "Gleicher Haushalt",
  "Familie",
  "Freunde",
]

const availableSlotsItems: any = [];

for (let index = 1; index < 4; index++) {
  availableSlotsItems.push(
    <MenuItem key={index} value={index}>
      {index.toString()}
    </MenuItem>
  );
}

const useStyles = makeStyles({
  form: {
    marginBottom: theme.spacing(2),
  },
});

export default function NewGroupForm() {
  const classes = useStyles();
  const methods = useFormContext();
  const {
    control,
    formState: {errors, touchedFields},
    getValues,
  } = methods;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <form className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Wähle einen Namen
            </Typography>

            <Controller
              name="name"
              control={control}
              rules={{required: true, minLength: 2}}
              defaultValue={getValues("name") ?? ""}
              render={({field}) => (
                <TextField
                  {...field}
                  error={!!errors.name}
                  helperText={
                    errors.name && touchedFields && "Gruppenname wird benötigt!"
                  }
                  label="Gruppenname"
                  variant="filled"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Beschreibe deine Gruppe
            </Typography>
            <Controller
              name="description"
              control={control}
              defaultValue={getValues("description") ?? ""}
              render={({field}) => (
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label="Beschreibung (optional)"
                  variant="filled"
                  fullWidth
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Preis
            </Typography>
            <TextField
              id="totalPrice"
              value={formContent?.price.amount}
              inputRef={register({ required: true })}
              error={errors.description ? true : false}
              helperText={errors.description && "Gesamtpreis wird benötigt!"}
              name="totalPrice"
              label="Gesamtpreis"
              variant="filled"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Wie viele Slots sind frei?
            </Typography>
            <Controller
              name="availableSlots"
              defaultValue={getValues("availableSlots") ?? ""}
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <TextField
                  {...field}
                  error={!!errors.availableSlots}
                  helperText={
                    errors.availableSlots &&
                    touchedFields &&
                    "Anzahl wird benötigt!"
                  }
                  select
                  variant="filled"
                  label="Freie Plätze"
                  fullWidth
                >
                  {availableSlotsItems}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Beziehung zu Mitgliedern
            </Typography>
            <Controller
              name="relationship"
              defaultValue={getValues("relationship") ?? ""}
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <TextField
                  {...field}
                  error={!!errors.relationship}
                  helperText={
                    errors.relationship &&
                    touchedFields &&
                    "Beziehung wird benötigt!"
                  }
                  select
                  variant="filled"
                  label="Beziehungsart"
                  fullWidth
                >
                  {relationShipItems.map((relation) => (
                    <MenuItem key={relation} value={relation}>
                      {relation}
                    </MenuItem>
                  ))
                  }
                </TextField>
              )}
            />
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Typography variant="h6" gutterBottom>*/}
          {/*    Wer kann deine Gruppe sehen?*/}
          {/*  </Typography>*/}
          {/*  <Controller*/}
          {/*    name="visibility"*/}
          {/*    control={control}*/}
          {/*    defaultValue=""*/}
          {/*    rules={{required: true}}*/}
          {/*    render={({field}) => (*/}
          {/*      <RadioGroup aria-label="visibility">*/}
          {/*        <FormControlLabel*/}
          {/*          {...field}*/}
          {/*          value="public"*/}
          {/*          control={<Radio/>}*/}
          {/*          label="Öffentlich (Deine Gruppe wird auf Zazmo veröffentlicht)"*/}
          {/*        />*/}
          {/*        <FormControlLabel*/}
          {/*          {...field}*/}
          {/*          value="private"*/}
          {/*          control={<Radio/>}*/}
          {/*          label="Privat (Nur Personen, die du einlädst, sehen deine Gruppe)"*/}
          {/*        />*/}
          {/*      </RadioGroup>*/}
          {/*    )}*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
      </form>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import NewGroupCategory from "./components/NewGroupCategory";
import NewGroupForm from "./components/NewGroupForm";
import NewGroupConfirm from "./components/NewGroupConfirm";
import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress, Box,
} from "@material-ui/core";
import { NewGroupSuccess } from "./components/NewGroupSuccess";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import { Group, NewGroup } from "../../interfaces/group.interface";
import useHttp from "../../hooks/use-http";
import { NewGroupDTO } from "../../interfaces/group.interface";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  media: {
    height: 100,
  },
  stepper: {
    marginTop: "64px",
    padding: theme.spacing(3, 1, 3),
    backgroundColor: "transparent",
  },
  content: {
    padding: theme.spacing(2),
    marginBottom: "80px",

    [theme.breakpoints.up("sm")]: {
      marginBottom: "unset",
    },
  },
  buttons: {
    width: "100%",
    position: "fixed",
    zIndex: 1000,
    left: 0,
    bottom: 0,
    height: 80,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2F2F2F",

    [theme.breakpoints.up("sm")]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  button: {
    minWidth: "145px",
    "&:only-child": {
      marginLeft: "auto",
    },

    // [theme.breakpoints.down("sm")]: {
    //   "&:only-child": {
    //     margin: "0 auto",
    //   },
    // },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContentText: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    textAlign: "center",
    alignItems: "center",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
  },
}));

const steps = ["Kategorie", "Gruppendetails", "Daten"];

function getStepContent(stepIndex: number, formContent: any) {
  switch (stepIndex) {
    case 0:
      return <NewGroupCategory />;
    case 1:
      return <NewGroupForm />;
    case 2:
      return <NewGroupConfirm />;
    default:
      throw new Error("Unknown step");
  }
}

const defaultGroup: NewGroup = {
  serviceID: "",
  name: "",
  description: "",
  availableSlots: "",
  relationship: "",
  visibility: "",
};

export default function NewGroupView() {
  const methods = useForm({ mode: "all" });
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [compiledForm, setCompiledForm] = useState<NewGroup>(defaultGroup);
  const [open, setOpen] = useState(false);
  const { watch, formState, getValues, unregister } = methods;
  const { isValid, errors } = formState;
  const {
    response,
    isLoading,
    error,
    sendRequest: sendGroupRequest,
  } = useHttp();

  const form = watch();

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(activeStep + 1);
        break;
      case 1:
        setActiveStep(activeStep + 1);
        break;
      case 2:
        setCompiledForm({ ...compiledForm });
        let visibility = compiledForm.visibility ?? "private";
        if (visibility === "public") {
          setOpen(true);
        } else {
          handleSubmit();
        }
        break;
      default:
        return "not a valid step";
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      switch (activeStep) {
        case 1:
          unregister("serviceID");
          unregister("name");
          unregister("description");
          unregister("availableSlots");
          unregister("relationship");
          unregister("visibility");
          break;
        case 2:
          unregister("email");
          break;
        default:
          return "not a valid step";
      }
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    setActiveStep(activeStep + 1);
    handleSubmit();
  };

  const createGroup = (groupData: any) => {
    console.log(groupData);
  };

  const handleSubmit = () => {
    console.log("submit", compiledForm);
    const newGroup: NewGroupDTO = {
      availableSlots: getValues("availableSlots"),
      description: getValues("description"),
      isPrivate: true,
      membershipRequest: false,
      name: getValues("name"),
      paymentType: "sepa",
      relationship: getValues("relationship"),
      serviceID: getValues("serviceID"),
      teaser: "",
    };

    console.log("new group", newGroup);

    sendGroupRequest(
      { url: "rest/v1/sharing-groups", method: "post", data: newGroup },
      createGroup
    ).then(() => {
      setActiveStep(activeStep + 1);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isEmpty = (obj: any) =>
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length;

  useEffect(() => {
    console.log("FORM CONTEXT", form, errors);
  }, [errors, form]);

  return (
    <FormProvider {...methods}>
      <main className={classes.layout}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <div className={classes.content}>
              {isLoading ? (
                  <Box
                    display="flex"
                    width="100%" height="100vh"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
              ) : error ? (
                <div>Error, the backend moved to the dark side.</div>
              ) : (
                // TODO: make response generic
                <NewGroupSuccess group={response as unknown as Group} />
              )}
            </div>
          ) : (
            <div>
              <div className={classes.content}>
                {getStepContent(activeStep, compiledForm)}
              </div>

              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Zurück
                  </Button>
                )}
                <Button
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Weiter
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent className={classes.dialogContentText}>
                    <DialogContentText id="alert-dialog-description">
                      Bitte bestätige, dass ich mein Passwort geändert habe.
                      Falls nicht, kannst du die Kosten nicht teilen und es wird
                      keine Auzahlung erfolgen.
                    </DialogContentText>
                    <img
                      className={classes.media}
                      src="/images/hand-confirm.png"
                      alt="confirm"
                    />
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleConfirm}
                      color="primary"
                      autoFocus
                    >
                      Ich bestätige
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          )}
        </React.Fragment>
      </main>
    </FormProvider>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles, LinearProgress } from "@material-ui/core";
import GroupDetailsOverview from "./components/GroupDetailsOverview";
import GroupDetailsAccessInfo from "./components/GroupDetailsAccessInfo";
import { Group } from "../../interfaces/group.interface";
import useHttp from "../../hooks/use-http";
import useUser from "../../context/User/useUser";

const useStyles = makeStyles((theme) => ({
  layout: {
    marginTop: "64px",
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export enum GroupDetailsState {
  groupInfo,
  accessInfo,
}

export default function GroupDetailsView() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { isLoading, error, sendRequest: fetchGroup } = useHttp();
  const [groupDetailsState, setGroupDetailsState] = useState<GroupDetailsState>(
    GroupDetailsState.groupInfo
  );
  const [group, setGroup] = useState<Group>();

  useEffect(() => {
    const transformGroup = (group: Group) => {
      setGroup(group);
    };
    fetchGroup({ url: `rest/v1/sharing-groups/${id}` }, transformGroup);
  }, [fetchGroup, id]);


  return (
    <React.Fragment>
      {isLoading && <LinearProgress />}
      {error && <div>Error, the backend moved to the dark side.</div>}
      {group && (
        <main className={classes.layout}>
          <React.Fragment>
            {groupDetailsState === GroupDetailsState.groupInfo ? (
              <GroupDetailsOverview
                group={group}
                setGroupDetailsState={setGroupDetailsState}
              />
            ) : (
              <GroupDetailsAccessInfo
                group={group}
                setGroupDetailsState={setGroupDetailsState}
              />
            )}
          </React.Fragment>
        </main>
      )}
    </React.Fragment>
  );
}

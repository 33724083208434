import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import UserContext from "./userContext";
import { reducer } from "./userReducer";
import { initialUserState } from "./userState";
import * as http from "../../utils/http";

/**
 * The main configuration to instantiate the `UserProvider`.
 */
export interface UserProviderOptions {
  /**
   * The child nodes your Provider has wrapped
   */
  children?: React.ReactNode;
}

/**
 * ```jsx
 * <UserProvider>
 *   <MyApp />
 * </UserProvider>
 * ```
 *
 * Provides the Auth0Context to its child components.
 */
const UserProvider = (opts: UserProviderOptions): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const { children } = opts;
  const [state, dispatch] = useReducer(reducer, initialUserState);

  useEffect(() => {
    (async (): Promise<void> => {
      const token = await getAccessTokenSilently();
      await http
        .authorized(token)
        .get("/rest/v1/users/me")
        .then((result) => {
          console.log(result);
          const user = result.data;
          dispatch({ type: "INITIALISED", user: user });
        })
        .catch(function (error) {
          dispatch({ type: "ERROR", error: loginError(error) });
        });
    })();
  }, [getAccessTokenSilently]);

  const registerUser = useCallback(
    async (newUser: any): Promise<void> => {
      const token = await getAccessTokenSilently();
      await http
        .authorized(token)
        .post("/rest/v1/users/register", newUser)
        .then((result) => {
          console.log(result);
          const user = result.data;
          dispatch({ type: "INITIALISED", user: user });
        })
        .catch(function (error) {
          dispatch({ type: "ERROR", error: loginError(error) });
        });
    },
    [getAccessTokenSilently]
  );

  return (
    <UserContext.Provider
      value={{
        ...state,
        registerUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const normalizeErrorFn = (fallbackMessage: string) => (
  error: Error | { error: string; error_description?: string } | ProgressEvent
): Error => {
  if ("error" in error) {
    return new Error(error.error);
  }
  if (error instanceof Error) {
    return error;
  }
  return new Error(fallbackMessage);
};

export const loginError = normalizeErrorFn("Login failed");

export default UserProvider;

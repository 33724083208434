export type User = any; // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * The user state which, when combined with the user methods, make up the return object of the `useUser` hook.
 */
export interface UserState {
  error?: Error;
  isOnboarded: boolean;
  isLoading: boolean;
  user?: User;
}

/**
 * The initial user state.
 */
export const initialUserState: UserState = {
  isOnboarded: false,
  isLoading: true,
};

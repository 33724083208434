import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";

export default function AdminView() {
  const [redirectTo, setRedirectTo] = useState(false);
  const { user, logout } = useAuth0();

  useEffect(() => {
    const roles = user!["https://app.zazmo.de/roles"];
    if (!roles.includes("Admin")) {
      setRedirectTo(true);
    }
  }, [user]);

  if (redirectTo) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Admin Page</h1>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

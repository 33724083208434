import {Route, Switch} from "react-router-dom";
import InvitationPage from "../pages/Invitation/InvitationPage";
import StyleguideView from "../pages/Styleguide/StyleguideView";
import HomeView from "../pages/Home/HomeView";
import ProfileView from "../pages/Profile/ProfileView";
import OnboardingView from "../pages/Onboarding/OnboardingView";
import NewGroupView from "../pages/NewGroup/NewGroupView";
import GroupDetailsView from "../pages/GroupDetails/GroupDetailsView";
import JoinGroupView from "../pages/JoinGroup/JoinGroupView";
import AdminView from "../pages/Administration/AdminView";
import { ProtectedRoute } from "./ProtectedRoute";
import React from "react";
import Navbar from "../components/Navbar/Navbar";
import useUser from "../context/User/useUser";


export const RouterConfig = () => {
  const { isOnboarded } = useUser();
  return (
    <div>
      {isOnboarded && <Navbar />}
      <Switch>
        <Route path="/invitation">
          <InvitationPage />
        </Route>
        <Route path="/styleguide">
          <StyleguideView />
        </Route>
        <ProtectedRoute path="/" exact component={HomeView} />
        <ProtectedRoute path="/profile" component={ProfileView} />
        <ProtectedRoute path="/onboarding" component={OnboardingView} />
        <ProtectedRoute path="/new-group" component={NewGroupView} />
        <ProtectedRoute
          path="/group-details/:id"
          component={GroupDetailsView}
        />
        <ProtectedRoute path="/join-group/:id" component={JoinGroupView} />
        <ProtectedRoute path="/admin" component={AdminView} />
      </Switch>
    </div>
  );
};
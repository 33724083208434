import React, { Dispatch, SetStateAction, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../Theme";
import { Service } from "../../../interfaces/service.interface";
import { categoryEntertainment } from "../../../constants/categories";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import WorkOutlinedIcon from "@material-ui/icons/WorkOutlined";
import CloseIcon from "@material-ui/icons/Close";
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles({
  dialogHeader: {
    minHeight: "128px",
  },
  listTitle: {
    marginLeft: theme.spacing(2),
  },
  dialogSearch: {
    margin: theme.spacing(2),
  },
  icon: {
    minWidth: "30px",
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  subCategory: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  backIcon: {
    paddingRight: theme.spacing(1),
  },
});

interface Props {
  services: Service[];
  setService: (service: Service) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

enum CategorySelectionState {
  mainCategorySelect,
  subCategorySelect,
  serviceSelect,
}

export const CategorySelection = ({ setService, setOpen, services }: Props) => {
  const classes = useStyles();
  const [subCategory, setSubCategory] = useState<string>("");
  const [
    categorySelection,
    setCategorySelection,
  ] = useState<CategorySelectionState>(
    CategorySelectionState.mainCategorySelect
  );

  const handleSearch = () => {
    console.log("search");
    console.log(subCategory);
  };

  const onBack = () => {
    switch (categorySelection) {
      case CategorySelectionState.mainCategorySelect:
        setOpen(false);
        break;
      case CategorySelectionState.subCategorySelect:
        setCategorySelection(CategorySelectionState.mainCategorySelect);
        break;
      case CategorySelectionState.serviceSelect:
        setCategorySelection(CategorySelectionState.subCategorySelect);
        break;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className={classes.dialogHeader}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Kategorien
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        {categorySelection === CategorySelectionState.mainCategorySelect ? (
          <div className={classes.dialogSearch}>
            <TextField
              id="selected-service"
              variant="filled"
              onChange={handleSearch}
              label="Kategorie"
              fullWidth
            />
          </div>
        ) : (
          <>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.subCategory}
            >
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={onBack}
                  aria-label="back"
                  className={classes.backIcon}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Grid>
              <Grid item>
                {categorySelection ===
                CategorySelectionState.subCategorySelect ? (
                  <Typography variant="h6">Entertainment</Typography>
                ) : (
                  <Typography variant="h6">Service</Typography>
                )}
              </Grid>
            </Grid>
            <Divider />
          </>
        )}
      </div>

      {categorySelection === CategorySelectionState.mainCategorySelect ? (
        <List>
          <ListItem
            button
            onClick={() =>
              setCategorySelection(CategorySelectionState.subCategorySelect)
            }
          >
            <ListItemIcon className={classes.icon}>
              <OndemandVideoIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle2" className={classes.listTitle}>
                  Entertainment
                </Typography>
              }
            />
            <KeyboardArrowRightIcon />
          </ListItem>
          <ListItem button>
            <ListItemIcon className={classes.icon}>
              <WorkOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle2" className={classes.listTitle}>
                  Arbeit
                </Typography>
              }
            />
            <KeyboardArrowRightIcon />
          </ListItem>
        </List>
      ) : categorySelection === CategorySelectionState.subCategorySelect ? (
        <List>
          {categoryEntertainment.map((subCategory) => (
            <ListItem
              key={subCategory}
              button
              onClick={() => {
                setSubCategory(subCategory);
                setCategorySelection(CategorySelectionState.serviceSelect);
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.listTitle}>
                    {subCategory}
                  </Typography>
                }
              />
              <KeyboardArrowRightIcon />
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          {services.map((service) => (
            <ListItem
              key={service.name}
              button
              onClick={() => {
                setService(service);
                setOpen(false);
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.listTitle}>
                    {service.name}
                  </Typography>
                }
              />
              <KeyboardArrowRightIcon />
            </ListItem>
          ))}
        </List>
      )}
    </React.Fragment>
  );
};

export default CategorySelection;

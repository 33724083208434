import {
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Group } from "../../../interfaces/group.interface";
import {TextFieldToClipboard} from "../../../components/TextFieldToClipboard";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: theme.spacing(3),
  },
  shareGroup: {
    marginTop: theme.spacing(4),
  },
}));

interface NewGroupSuccessProps {
  group: Group;
}

export function NewGroupSuccess({ group }: NewGroupSuccessProps) {
  const classes = useStyles();
  const [socialLink, setSocialLink] = useState<string>("");

  useEffect(() => {
    setSocialLink(`${process.env.REACT_APP_CLIENT_BASE_URL}/group-details/${group.id}`);
  },[group.id])

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        Cool!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Du hast deine erste Gruppe gegründet!
      </Typography>
      <Typography variant="body2">
        Wenn alle Slots gefüllt werden können, erhältst du 13,50 €/Monat!
      </Typography>
      <div className={classes.button}>
        <Typography variant="body1" gutterBottom>
          Mach auf deine Sharing Gruppe aufmerksam um die Slots zu füllen!
        </Typography>
       <TextFieldToClipboard text={socialLink} />
      </div>

      <Button
        component={Link}
        to={`/group-details/${group.id}`}
        variant="contained"
        color="primary"
        fullWidth
        className={classes.button}
      >
        Zu deiner Gruppe
      </Button>
    </React.Fragment>
  );
}
import React from "react";
import AdminDetailsCard from "./AdminDetailsCard";
import GroupDetailsCard from "./GroupDetailsCard";
import GroupDetailsFooter from "./GroupDetailsFooter";
import GroupDetailsLogo from "./GroupDetailsLogo";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import NextDebitCard from "./NextDebitCard";
import PaymentInfoButton from "./PaymentInfoButton";
import CloseIcon from "@material-ui/icons/Close";
import useUser from "../../../context/User/useUser";
import { GroupDetailsState } from "../GroupDetailsView";
import { Member } from "../../../interfaces/member.interface";
import { Group } from "../../../interfaces/group.interface";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    minHeight: "calc(100vh - 276px)",
    [theme.breakpoints.up("sm")]: {
      minHeight: "unset",
    },
  },
  media: {
    height: 100,
    margin: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContentText: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    textAlign: "center",
    alignItems: "center",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface GroupDetailsOverviewProps {
  group: Group;
  setGroupDetailsState: (state: GroupDetailsState) => void;
}

export default function GroupDetailsOverview(props: GroupDetailsOverviewProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { user } = useUser();
  const group = props.group;

  const handleConfirm = () => {
    setOpen(false);
    props.setGroupDetailsState(GroupDetailsState.accessInfo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = (isPrivate: boolean) => {
    if (isPrivate) {
      props.setGroupDetailsState(GroupDetailsState.accessInfo);
    } else {
      setOpen(true);
    }
  };

  const isMemberOfGroup = (members: Member[]): boolean => {
    return members.some(({ id }) => id === user.userID)
  };

  // console.log("User: ", user);

  return (
    <React.Fragment>
      <GroupDetailsLogo
        title={group.service.name}
        src={group.service.logos.imageUrl}
      />
      <div className={classes.content}>
        <div className={classes.card}>
          <GroupDetailsCard
            name={group.name}
            isPrivate={group.isPrivate}
            description={group.description}
            service={group.service}
            groupCreated={new Date()}
            members={group.members}
          />
        </div>

        <div className={classes.card}>
          <AdminDetailsCard
            name={group.members[0].name}
            accountCreated={new Date(group.members[0].accountCreated)}
            totalRatings={group.members[0].totalRatings}
            rating={group.members[0].rating}
            trustLevel={group.members[0].trustLevel}
          />
        </div>
        {isMemberOfGroup(group.members) && (
          <>
            <div className={classes.card}>
              <NextDebitCard nextBilling={new Date()} />
            </div>

            <div className={classes.card}>
              <PaymentInfoButton onClick={() => onClick(group.isPrivate)} />
            </div>
          </>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContentText}>
            <DialogContentText variant="h6" id="alert-dialog-description">
              Vertrauen &amp; Sicherheit sind die Basis damit Zazmo Sharing
              funktioniert!
            </DialogContentText>
            <img
              className={classes.media}
              src="/images/finger-crossed.png"
              alt="confirm"
            />
            <DialogContentText variant="subtitle2">
              Deshalb verfolgen wir eine{" "}
              <Typography component="span" color="primary">
                Null-Toleranz-Strategie
              </Typography>{" "}
              bei Personen, die die Zugangsdaten mit Personen teilen, die nicht
              Mitglieder der Sharing-Gruppe sind.
            </DialogContentText>
            <DialogContentText variant="subtitle2">
              Hiermit bestätige ich, dass ich mir bewusst bin, bei
              Regelverstößen vom Netzwerk ausgeschlossen werde und eine
              Geldstrafe von{" "}
              <Typography component="span" color="primary">
                50 €
              </Typography>{" "}
              zahlen muss.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirm}
              color="primary"
              autoFocus
            >
              Ich bestätige
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {!isMemberOfGroup(group.members) && (
        <GroupDetailsFooter
          id={group.id}
          totalPrice={group.service.price.amount}
          availableSlots={group.availableSlots}
        />
      )}
    </React.Fragment>
  );
}

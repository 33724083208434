import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./Theme";
import { CssBaseline } from "@material-ui/core";
import Loading from "./components/Loading";
import Navbar from "./components/Navbar/Navbar";
import UserProvider from "./context/User/userProvider";
import {RouterConfig} from "./navigation/RouterConfig";
import { configure } from 'axios-hooks'
import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL
})

configure({ axios })

export default function App() {
  const { isLoading, error } = useAuth0();

  if (error) return <div>Oops... {error.message}</div>;
  if (isLoading) return <Loading />;

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <CssBaseline />
          <RouterConfig />
        </UserProvider>
      </ThemeProvider>
    </Router>
  );
}

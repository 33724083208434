import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import OndemandVideo from "@material-ui/icons/OndemandVideo";
import { Avatar, Grid, Paper } from "@material-ui/core";
import { theme } from "../../../Theme";
import { Service } from "../../../interfaces/service.interface";
import { Member } from "../../../interfaces/member.interface";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "16px",
  },
  image: {
    width: 64,
    height: 64,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  avatar: {
    width: 20,
    height: 20,
    fontSize: 12,
    color: theme.palette.getContrastText("#F57D7E"),
    backgroundColor: "#F57D7E",
  },
  boldFont: {
    fontWeight: "bold",
  },
  marginTop: {
    marginTop: 12,
  },
  slot: {
    width: 25,
    height: 5,
  },
});

interface GroupDescriptionCardProps {
  name: string;
  service: Service;
  members: Member[];
  availableSlots: number;
}

export default function GroupDescriptionCard(props: GroupDescriptionCardProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{props.name}</Typography>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Grid container spacing={1} direction="row">
                  <Grid item>
                    <OndemandVideo fontSize="small" color="primary" />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" color="primary">
                      {props.service.name} {props.service.type}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      {props.members[0].name.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" color="primary">
                      Admin: {props.members[0].name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {props.availableSlots} Slot
                {props.availableSlots > 1 ? "s" : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as http from "../../utils/http";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default function ProfileView() {
  const classes = useStyles();
  const { getAccessTokenSilently, user, logout } = useAuth0();

  const [message, setMessage] = useState("");

  const checkMe = async () => {
    const token = await getAccessTokenSilently();

    http
      .authorized(token)
      .get("/rest/v1/users/me")
      .then((data) => {
        console.log(data);
        setMessage(data.statusText);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  console.log(user);

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>Home</h1>
          </Grid>
          <Grid item xs={12}>
            <h3>Profile</h3>
            <div>
              <img src={user?.picture} alt={user?.name} />
              <h4>Name: {user?.name}</h4>
              <p>Email: {user?.email}</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <h3>External API</h3>
            <p>
              Use the buttons to call the me route of zazmo API. The protected
              API call has an access token in its authorization header. The API
              server will validate the access token using the Auth0 Audience
              value.
            </p>
          </Grid>
          <Grid item xs={12}>
            <div
              className="btn-group mt-5"
              role="group"
              aria-label="External API Requests Examples"
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={checkMe}
              >
                Test Protected with Axios
              </button>
            </div>
            {message && (
              <div className="mt-5">
                <h6 className="muted">Result</h6>
                <div className="container-fluid">
                  <div className="row">
                    <code className="col-12 text-light bg-dark p-4">
                      {message}
                    </code>
                  </div>
                </div>
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}

import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route } from "react-router-dom";
import withOnboarding from "../components/HOCs/withOnboarding";

export const ProtectedRoute = ({
  component,
  ...args
}: React.PropsWithChildren<any>) => (
  <Route
    render={(props) => {
      // let Component = withAuthenticationRequired(component, {});
      // TODO: use auth0
      let Component = withAuthenticationRequired(withOnboarding(component), {});
      // let Component = withOnboarding(withAuthenticationRequired(component, {}));
      return <Component {...props} />;
    }}
    {...args}
  />
);

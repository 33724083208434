

export const getAge = (dateString: string) => {
  let today = new Date();
  let dmy = dateString.split(".");
  let birthDate = new Date(
    Number(dmy[2]),
    Number(dmy[1]) - 1,
    Number(dmy[0])
  );
  // let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
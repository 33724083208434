import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel, makeStyles,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { countries } from "../../../utils/countries";
import { nationalities } from "../../../utils/nationalities";
import { useHistory } from "react-router-dom";
import useUser from "../../../context/User/useUser";
import { NewUserDTO } from "../../../interfaces/user.interface";
import NumberFormat from "react-number-format";
import { birthdayFormat } from "../../../utils/format";
import { getAge } from "../../../utils/dateToAge";

interface UserOnboarding {
  firstname: string;
  lastname: string;
  birthday: string;
  zip: string;
  street: string;
  city: string;
  country: string;
  nationality: string;
  newsletter: boolean;
  tos: boolean;
}

const useStyles = makeStyles((theme) => ({
  customInput: {
    display: "block",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid white",
    color: "black",
    padding: "10px 15px",
    marginBottom: "10px",
    fontSize: "14px",
  },
}));

export function OnboardingForm() {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm<UserOnboarding>({ mode: "all" });
  const { isValid, errors, touchedFields } = formState;
  const { user } = useAuth0();
  const history = useHistory();
  const { registerUser } = useUser();

  const onSubmit = async (data: UserOnboarding) => {
    console.log("data", data);

    if (getAge(data.birthday) < 18) {
      console.log("too young");
    } else {
      const newUser: NewUserDTO = {
        firstname: data.firstname,
        lastname: data.lastname,
        address: {
          addressLine1: data.street,
          zip: data.zip,
          city: data.city,
          country: data.country,
        },
        nationality: data.nationality,
        birthday: data.birthday,
        newsletter: data.newsletter,
        privacy: data.tos,
        tos: data.tos,
        email: user?.email!,
        emailVerified: user?.emailVerified,
        username: user?.username,
      };

      registerUser(newUser).then(() => {
        history.push("/");
      });
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              {...register("firstname", { required: true })}
              error={!!errors.firstname}
              helperText={errors.firstname && "Vorname wird benötigt!"}
              label="Vorname"
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="lastname"
              {...register("lastname", { required: true })}
              error={!!errors.lastname}
              helperText={errors.lastname && "Nachname wird benötigt!"}
              label="Nachname"
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => {
                return (
                  <NumberFormat
                    label="Geburtstag"
                    variant="filled"
                    format={birthdayFormat}
                    placeholder="DD.MM.YYYY"
                    mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                    fullWidth
                    control={control}
                    {...field}
                    customInput={TextField}
                    onValueChange={(c) => {
                      field.onChange(c.value);
                    }}
                  />
                );
              }}
              name="birthday"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              defaultValue="DE"
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  variant="filled"
                  label="Nationalität"
                  fullWidth
                >
                  {nationalities.map((option) => (
                    <MenuItem
                      key={option.countryCode}
                      value={option.countryCode}
                    >
                      {option.nationality}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="nationality"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => {
                return (
                  <NumberFormat
                    label="Postleitzahl"
                    variant="filled"
                    format="#####"
                    fullWidth
                    control={control}
                    {...field}
                    customInput={TextField}
                    onValueChange={(c) => {
                      field.onChange(c.value);
                    }}
                  />
                );
              }}
              name="zip"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="street"
              {...register("street", { required: true })}
              error={!!errors.street}
              helperText={errors.street && "Straße wird benötigt!"}
              label="Straße"
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="country"
              control={control}
              defaultValue="DE"
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  variant="filled"
                  label="Dein Wohnsitz"
                  fullWidth
                  disabled
                >
                  {countries.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="tos"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      color="primary"
                    />
                  }
                  label="AGBs und Datenschutz akzeptieren"
                />
              )}
            />
            <Controller
              name="newsletter"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      color="primary"
                    />
                  }
                  label="Halt mich auf den neuesten Stand!"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!isValid}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              Okay fertig!
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
